/* eslint-disable indent */
import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
// import chunk from 'lodash.chunk';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
import ArchivePostSingle from 'components/ArchivePostSingle';

// import { Main } from 'styles/blog';
import BlogTop from '../components/BlogTop/BlogTop';
import 'styles/blog.scss';
const BlogIndex = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
  pageContext,
}) => {
  const { currentPage, numPages } = pageContext;

  // eslint-disable-next-line no-unused-vars

  return (
    <Layout location={location} showBottomPopup={false}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Blog | ruttl"
            description="Improve your knowledge and stay updated with new trends in designing with ruttl's blog page that is regularly updated with latest content on web designing."
            url="https://ruttl.com/blog/"
          />
          <main className="blog-styled-main">
            <BlogTop />
            <section className="post-list-main" id="blogs">
              <div className="container">
                <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                  {allPosts.map((edge) => (
                    <React.Fragment key={edge.node?.id}>
                      <ArchivePostSingle
                        link={
                          edge.node?.publishDate
                            ? `/blog/${edge.node?.slug}/`
                            : `/customer-stories/${edge.node?.slug}/`
                        }
                        title={edge.node?.title}
                        image={
                          edge.node?.archiveThumbnail
                            ? edge.node?.archiveThumbnail?.fluid
                            : edge.node.featuredImage.fluid
                        }
                        excerpt={
                          edge.node?.excerpt?.excerpt
                            ? edge.node?.excerpt?.excerpt
                            : edge.node?.excerpt
                        }
                        category={
                          edge.node?.category
                            ? edge.node?.category
                            : [{ name: edge.node?.tag }]
                        }
                      />
                    </React.Fragment>
                  ))}
                </div>
                <div className="pagination flex">
                  <Link
                    className={currentPage === 1 ? 'disabled' : ''}
                    to={
                      // eslint-disable-next-line no-nested-ternary
                      currentPage === 1
                        ? ''
                        : currentPage === 2
                        ? `/blog/#blogs`
                        : `/blog/${currentPage - 1}/#blogs`
                    }>
                    Previous
                  </Link>
                  <div className="page-numbers flex">
                    <Link
                      className={`${currentPage === 1 ? 'active' : ''}`}
                      to="/blog/#blogs">
                      1
                    </Link>
                    {currentPage > 4 ? (
                      <span className="page-dots">...</span>
                    ) : null}
                    {Array.from({ length: numPages }, (_, i) =>
                      i === 0 ||
                      i > numPages - 2 ||
                      i + 4 <= currentPage ||
                      i - 2 >= currentPage ? null : (
                        <Link
                          key={`pagination-number${i + 1}`}
                          to={`/blog/${i === 0 ? '' : i + 1}/#blogs`}
                          className={currentPage === i + 1 ? 'active' : ''}>
                          {i + 1}
                        </Link>
                      ),
                    )}
                    {currentPage < numPages - 3 ? (
                      <span className="page-dots">...</span>
                    ) : null}
                    <Link
                      className={`${currentPage === numPages ? 'active' : ''}`}
                      to={`/blog/${numPages}/#blogs`}>
                      {numPages}
                    </Link>
                  </div>
                  <Link
                    className={currentPage === numPages ? 'disabled' : ''}
                    to={
                      currentPage === numPages
                        ? ''
                        : `/blog/${currentPage + 1}/#blogs`
                    }>
                    Next
                  </Link>
                </div>
              </div>
            </section>
            <GetStartedCenter toggleSignup={toggleSignup} />
          </main>
        </>
      )}
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC } #      limit: 10
      limit: $limit
      skip: $skip
      filter: {
        category: { elemMatch: { name: { nin: ["Product Updates"] } } }
        knowledgeBox: { ne: true }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
  }
`;
